@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Inter:wght@400;500&family=Space+Grotesk:wght@400;500;700&display=swap");

::-webkit-scrollbar {
  width: 6px;
  border-radius: "8px";
  background-color: rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-thumb {
  background-color: #0f172b;
}
/* @media (max-width: 768px) {
  .bar_content {
    animation: move 15s linear infinite;
  }
  @keyframes move {
    to {
      transform: translateX(-310%);
    }
  }
} */

/* NEW SECTION */

.fade-in-out {
  opacity: 0;
  animation: fade-in 7s ease-in forwards infinite,
    fade-out 7s ease-out forwards infinite;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.btn:hover {
  padding: 35px 50px 35px 40px;
}
.btns:hover .arrow {
  transition: 0.5s ease-in-out;
  position: relative;
  transform: rotate(45deg);
}
.btn:hover .arrow {
  transition: 0.5s ease-in-out;
  position: relative;
  transform: rotate(45deg);
}

.review_overlay {
  background: linear-gradient(360deg, #fff 19.19%, rgba(204, 213, 236, 0) 300%);
}
.full_width {
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  margin-right: calc((100% - 100vw) / 2);
}

.bar_content {
  display: flex;
  animation: slide 25s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .bar_content {
    animation: slide 10s linear infinite;
  }
  @keyframes slide {
    0% {
      transform: translateX(80%);
    }
    100% {
      transform: translateX(-330%);
    }
  }
}

.no_scroll::-webkit-scrollbar {
  display: none;
}

.no_scroller::-webkit-scrollbar {
  width: 0;
  border-radius: 8px;
  background-color: transparent;
}

.no_scroller::-webkit-scrollbar-thumb {
  background-color: transparent;
}
